'use client';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import logoBdDark from '../../../public/images/logos/possums_icon-dark_bg.png';
import { IconMenu2, IconX } from '@tabler/icons-react';
import AuthLinks from './AuthLinks';
import GoogleTagManager from '@dr-pam/common-components/Components/Page/GoogleTagManager';
import { usePathname, useRouter } from 'next/navigation';

export type NavBarProps = {
	gtmId: string;
};

export default function NavBar(props: NavBarProps) {
	const { gtmId } = props;
	const [showNavbar, setShowNavbar] = useState(false);
	const pathname = usePathname();
	const router = useRouter();

	const toggleMenu = () => {
		setShowNavbar(!showNavbar);
		if (!showNavbar) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
		}
	};
	const closeMenu = () => {
		setShowNavbar(false);
		document.body.classList.remove('menu-open');
	};

	useEffect(() => {
		// Close the menu whenever the route changes
		closeMenu();
	}, [pathname]);

	const onBrowseProgramClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
		// If we are already on the browse program page, use a search param to indicate to the page to scroll to the top (and do other things)
		if (pathname === '/browse-the-program') {
			event.preventDefault();
			router.replace('/browse-the-program?top');
		}
	};

	return (
		<header className="navbar dark-bg">
			<GoogleTagManager gtmId={gtmId} />
			<Link className="logo-container" href="/">
				<Image
					src={logoBdDark}
					className="icon-logo"
					alt="Logo - The Possums baby and toddler sleep program."
					priority={true}
				/>
				<p>
					the possums
					<br />
					<span className="emphasised-text">sleep program</span>
				</p>
			</Link>
			<nav>
				<div className={`hamburger-menu ${showNavbar ? 'hidden' : ''}`} onClick={toggleMenu}>
					<IconMenu2 />
				</div>
				<div className={`menu-links ${showNavbar ? 'show' : ''}`}>
					<div className="close-menu" onClick={closeMenu}>
						<IconX size={32} />
					</div>
					<Link href="/find-essentials">find essentials</Link>
					<Link href="/browse-the-program" onClick={onBrowseProgramClicked}>
						browse the program
					</Link>
					<Link href="/about-program">about the program</Link>
					<Link href="/speak-to-an-ndc-practitioner">speak to an NDC practitioner</Link>
					<AuthLinks />
				</div>
			</nav>
		</header>
	);
}
