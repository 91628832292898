'use client';

import React, { useEffect, useState } from 'react';
import SessionProvider, { SessionProviderProps } from '@dr-pam/common-components/Components/Page/SessionProvider';
import ClientFingerprintService from '@dr-pam/common-components/Services/ClientFingerprintService';
import { SessionData } from 'express-session';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import { useHotjarService } from '@dr-pam/common-components/Services/HotjarService';
import HotjarUserData from '../../config/HotjarUserData';

export const dynamic = 'force-dynamic';

export default function PspSessionProvider(props: SessionProviderProps<PrismaJson.ApiSessionData>) {
	const { children, sessionData: providedSessionData } = props;

	const [sessionData, setSessionData] = useState<PrismaJson.ApiSessionData | null>(providedSessionData);

	const hotjarService = useHotjarService();

	useEffect(() => {
		const fingerprintId = providedSessionData?.fingerprintId;

		if (!fingerprintId) {
			notifyClientFingerprint().then((sessionData) => {
				hotjarService.sendEvent('FINGERPRINT_COLLECTED');
				setSessionData(sessionData);
			});
		} else {
			setSessionData(providedSessionData);
		}
	}, [hotjarService, providedSessionData]);

	// Whenever the session data changes, update the Hotjar user data
	useEffect(() => {
		if (sessionData) {
			hotjarService.sendEvent('SESSION_STARTED');

			if (sessionData.user?.id) {
				hotjarService.sendEvent('SESSION_WITH_LOGIN');
			} else {
				hotjarService.sendEvent('SESSION_WITHOUT_LOGIN');
			}

			hotjarService.identify<HotjarUserData>(sessionData.user?.id ?? null, {
				sessionId: sessionData.sessionId,
				fingerprintId: sessionData.fingerprintId,
				email: sessionData.user?.email,
				name: sessionData.user?.fullName,
			});
		} else {
			hotjarService.sendEvent('NO_SESSION');
		}
	}, [hotjarService, sessionData]);

	return <SessionProvider sessionData={sessionData}>{children}</SessionProvider>;
}

async function notifyClientFingerprint(): Promise<SessionData> {
	const fingerprintId = await ClientFingerprintService.getFingerprintId();
	const request = FetchUtils.postJson<SessionData>('/hello', { fingerprintId });
	const sessionData = await request.response;
	return sessionData;
}
